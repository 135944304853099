import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faXmark, faUser, faCirclePlus, faLightbulb, faLocationDot, faEllipsis, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import mixpanel from "mixpanel-browser";

import CoreApp from './CoreApp';
import Landing from './Landing';

import { auth, signInWithGoogle, userSignOut } from './functions/firebase';
import { getUIDFromURL } from './functions/util_fn';
import Profile from './pages/profile';
import NewPost from './pages/newPost';
import AllPostsFeed from './components/allPostsFeed';
import HobbiesList from './components/hobbiesList';
import HobbyPage from './pages/hobbyPage';
import EditProfile from './pages/editProfile';
import SinglePostPage from './pages/singlePostPage';
import SingleProjectPage from './pages/singleProjectPage';
import ProjectGallery from './pages/ProjectGallery';
import ProjectsList from './components/projectsList';
import AllUsers from './pages/AllUsers';

function App() {

  // Near entry of your product, init Mixpanel
  mixpanel.init("073900a606ddd9cc7dc171a756852b27", {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });

  const [user, setUser] = useState(null);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [loading, setLoading] = useState(true);

  library.add(fas, faXmark, faUser, faCirclePlus, faLightbulb, faLocationDot, faEllipsis, faChevronLeft);

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('*** User logged in', user);

        mixpanel.identify(user.uid);

        setAuthenticatedUser(user);

        const userIdFromUrl = getUIDFromURL(location.pathname);

        if(userIdFromUrl === user.uid || userIdFromUrl === null){
          setUser(user);
        } else {
          setUser({ uid: userIdFromUrl });
        }
      } else {
        console.log('*** No user logged in');
        
        // Check if the URL contains a user ID
        const userIdFromUrl = getUIDFromURL(location.pathname);
        
        if (userIdFromUrl != null) {
          setUser({ uid: userIdFromUrl });
        } else {
          setUser(null);
        }
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [location]);


  const signInThenRedirect = async () => {
    var didSignIn = await signInWithGoogle();
    console.log(didSignIn);
    if (didSignIn) {
      navigate(`/u/${user.uid}/profile/feed`,{});
    }
  }

  if (loading) {
      return <div></div>;
  }

  return (
    <>
      <Routes>
        <Route path="/u">
          <Route path=":userID" element={<CoreApp user={user} />}>
            <Route path="profile" element={<Profile user={user} />}>
              <Route path="feed" element={<AllPostsFeed user={user}/>}></Route>
              <Route path="hobbies" element={<HobbiesList user={user}/>}></Route>
              <Route path="projects" element={<ProjectsList user={user}/>} />
            </Route>
            <Route path="hobby/:hid" element={<HobbyPage user={user} />} />
            <Route path="project/:pid" element={<SingleProjectPage user={user} />} />
            <Route path="project/:pid/gallery" element={<ProjectGallery user={user} />} />
            <Route path="post/:postId" element={<SinglePostPage user={user} />} />
            <Route path="new" element={<NewPost user={user}/>}></Route>
            <Route path="edit" element={<EditProfile user={user}/>}></Route>
            <Route path="edit-post/:postId" element={<NewPost user={user} mode="edit" />}></Route>
          </Route>
        </Route>
        <Route path="/" element={<Landing user={user} GoogleLogIn={signInThenRedirect} />}></Route>
        <Route path="/allusers" element={<AllUsers />}></Route>
      </Routes>
    </>
  );
}

export default App;
