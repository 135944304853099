import React from 'react';
import { Row, Col, Container, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import '../style/projects.css';
import { getProjects } from '../functions/firebase';
import { useEffect, useState } from 'react';

function ProjectsList(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const hobbyId = location.pathname.split('/hobby/')[1]?.split('/')[0];
      const projectsData = await getProjects(props.user.uid, hobbyId || null);
      setProjects(projectsData);
    };

    fetchProjects();
  }, [props.user.uid, location.pathname]);

  const goToProjectPage = (project) => {
    const hobbyId = location.pathname.split('/hobby/')[1]?.split('/')[0];
    navigate(`/u/${props.user.uid}/project/${project.id}`, {
      state: {
        "origin_url": location.pathname,
        "hobby_id": hobbyId || null,
        "project": project
      }
    });
  };

  const handleCarouselTouch = (e) => {
    e.stopPropagation();  // This prevents the event from bubbling up to the page swipe handler
  };

  const renderProjects = (projectsList) => (
    projectsList.map((project, index) => (
      <Row className="g-0 m-0" key={index}>
        <Col xs="12" className="p-0">
          <div className="project-card" onClick={() => goToProjectPage(project)}>
            <div style={{ position: 'relative' }}>
              {project.status === 'active' && (
                <span className="status-pill active">Active</span>
              )}
              <div 
                onTouchStart={handleCarouselTouch}
                onTouchMove={handleCarouselTouch}
                onTouchEnd={handleCarouselTouch}
              >
                <Carousel interval={null} controls={false} className="project-carousel">
                  {project.gallery ? (
                    // Show gallery images if they exist
                    project.gallery.map((imageUrl, index) => (
                      <Carousel.Item key={index}>
                        <div
                          className="project-image"
                          style={{
                            backgroundImage: `url(${imageUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '200px'
                          }}
                        />
                      </Carousel.Item>
                    ))
                  ) : project.imageUrl && (
                    // Fall back to single imageUrl if no gallery
                    <Carousel.Item>
                      <div
                        className="project-image"
                        style={{
                          backgroundImage: `url(${project.imageUrl})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          height: '200px'
                        }}
                      />
                    </Carousel.Item>
                  )}
                </Carousel>
              </div>
            </div>
            <div className="project-info">
              <Container>
                <Row>
                  <Col xs="11">
                    <h3>{project.title}</h3>
                    <p className="preview-project-description project-description">
                      {project.description}
                    </p>
                  </Col>
                  <Col xs="1" className="right-align subtext-gray">
                    <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Col>
      </Row>
    ))
  );

  // Sort projects into active and completed
  const activeProjects = projects.filter(p => p.status === "active");
  const completedProjects = projects.filter(p => p.status === "completed");

  return (
    <div className="projects-list content-below-nav">
      <Container fluid className="p-0">
        {activeProjects.length > 0 && (
          <>
            <h2 className="section-title">Active Projects</h2>
            {renderProjects(activeProjects)}
          </>
        )}
        
        {completedProjects.length > 0 && (
          <>
            <h2 className="section-title">Completed Projects</h2>
            {renderProjects(completedProjects)}
          </>
        )}
      </Container>
    </div>
  );
}

export default ProjectsList;
