import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ProjectGallery() {
  const location = useLocation();
  const navigate = useNavigate();
  const { photos, selectedIndex, projectTitle, returnPath } = location.state || { 
    photos: [], 
    selectedIndex: 0, 
    projectTitle: '',
    returnPath: '/'
  };

  const [focusedImage, setFocusedImage] = useState(null);

  const goBack = () => {
    navigate(-1);
  };

  const handleImageClick = (photo) => {
    setFocusedImage(photo);
  };

  const closeFocusedImage = () => {
    setFocusedImage(null);
  };

  return (
    <div className="page-container">
      <div className='page-header-nav'>
        <Container fluid>
          <Row>
            <Col xs="2">
              <div className='circ-btn subtext-gray' onClick={goBack}>
                <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
              </div>
            </Col>
            <Col className='title' xs="8">
              <span>{projectTitle}</span>
            </Col>
            <Col xs="2"></Col>
          </Row>
        </Container>
      </div>

      <div className="gallery-page-content content-below-nav">
        <div className="gallery-page-grid">
          <div className="gallery-column">
            {photos
              .filter((_, index) => index % 2 === 0)
              .map((photo, index) => (
                <div 
                  key={index} 
                  className="gallery-page-item"
                  onClick={() => handleImageClick(photo)}
                >
                  <img 
                    src={photo} 
                    alt={`${projectTitle} - Photo ${index * 2 + 1}`}
                    loading="lazy"
                  />
                </div>
              ))}
          </div>
          <div className="gallery-column">
            {photos
              .filter((_, index) => index % 2 === 1)
              .map((photo, index) => (
                <div 
                  key={index} 
                  className="gallery-page-item"
                  onClick={() => handleImageClick(photo)}
                >
                  <img 
                    src={photo} 
                    alt={`${projectTitle} - Photo ${index * 2 + 2}`}
                    loading="lazy"
                  />
                </div>
              ))}
          </div>
        </div>

        {focusedImage && (
          <div className="focused-image-overlay" onClick={closeFocusedImage}>
            <div className="focused-image-container">
              <button className="close-button" onClick={closeFocusedImage}>
                <FontAwesomeIcon icon="fa-solid fa-xmark" />
              </button>
              <img src={focusedImage} alt="Focused view" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectGallery; 