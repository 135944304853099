import React from 'react';
import { Container } from 'react-bootstrap';
import HobbySelector from '../HobbySelector';

function ProjectForm({ user, onSubmit, profileInfo, postInfo, addPostInfo, postContent, handlePostContentChange, selectedHobbyIndex, handleHobbySelect, projectState, setProjectState }) {
    return (
        <Container fluid className='content-below-nav'>
            <p style={{marginTop: '54px'}}>Select Hobby</p>
            <HobbySelector 
                hobbies={profileInfo?.hobbies}
                selectedIndex={selectedHobbyIndex}
                onSelect={handleHobbySelect}
            />

            <p style={{marginTop: '16px'}}>Project Name</p>
            <input
                type="text"
                className='outline'
                value={postInfo['Add Title'] || ''}
                onChange={(e) => addPostInfo('Add Title', e.target.value)}
                required
            />

            <div className="project-state-selector">
                <p style={{marginTop: '16px'}}>Project Status</p>
                <div className="toggle-container">
                    <div 
                        className={`toggle-option ${projectState === 'active' ? 'active' : ''}`}
                        onClick={() => setProjectState('active')}
                    >
                        Active
                    </div>
                    <div 
                        className={`toggle-option ${projectState === 'completed' ? 'active' : ''}`}
                        onClick={() => setProjectState('completed')}
                    >
                        Completed
                    </div>
                </div>
            </div>
            <p style={{marginTop: '16px'}}>Description</p>
            <textarea 
                placeholder="Briefly describe your project..."
                className='outline'
                value={postContent}
                onChange={handlePostContentChange}
            />
        </Container>
    );
}

export default ProjectForm; 