import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../style/projects.css';
import { toast } from 'react-toastify';
import PopupOptionsMenu from '../components/popupOptionsMenu';
import { handleShareLink } from '../functions/util_fn';
import ProjectTimeline from '../components/ProjectTimeline';
import EditProject from './editProject';
import { getProjectById, getPostsByProjectID } from '../functions/firebase';

function SingleProjectPage(props) {
  const { pid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [showProjectOptions, setShowProjectOptions] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showEditPage, setShowEditPage] = useState(false);
  const [project, setProject] = useState(location.state?.project || {
    id: pid,
    title: "Loading...",
    description: "Loading...",
    status: "active",
    photos: []
  });
  const [timelinePosts, setTimelinePosts] = useState([]);
  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const fetchProjectData = async () => {
      let projectData = location.state?.project;
      
      // If no project data in location state, fetch it
      if (!projectData) {
        projectData = await getProjectById(props.user.uid, pid);
        if (projectData) {
          setProject(projectData);
        } else {
          toast.error("Project not found");
          goBack();
          return;
        }
      }

      // Fetch project posts
      const posts = await getPostsByProjectID(props.user.uid, pid);
      setTimelinePosts(posts);

      // Combine project gallery images with post images if enabled
      const projectGallery = projectData.gallery || [];
      let combinedImages = [...projectGallery];
      
      // Only include post images if the setting is enabled (default to true if not set)
      if (projectData.includePostPhotos !== false) {
        const postImages = posts
          .filter(post => post.imageUrl)
          .map(post => post.imageUrl);
        combinedImages = [...projectGallery, ...postImages];
      }
      
      setAllImages(combinedImages);
    };

    fetchProjectData();
  }, []);

  const goBack = () => {
    if (location.state?.origin_url) {
      navigate(location.state.origin_url, {
        state: { activeTab: 'projects' }
      });
    } else {
      // If no origin URL, try to use hobby_id to go back to hobby page
      const hobbyId = location.state?.hobby_id;
      if (hobbyId) {
        navigate(`/u/${props.user.uid}/hobby/${hobbyId}`, {
          state: { activeTab: 'projects' }
        });
      } else {
        // Fallback to profile
        navigate(`/u/${props.user.uid}/profile/feed`);
      }
    }
  };

  const navigateToGallery = (index) => {
    navigate(`gallery`, {
      state: { 
        photos: allImages,
        selectedIndex: index,
        projectTitle: project.title,
        returnPath: location.pathname
      }
    });
  };

  const toggleShowProjectOptions = () => {
    setShowProjectOptions(!showProjectOptions);
  }

  const handleShareProjectPage = async () => {
    await handleShareLink();
    toggleShowProjectOptions();
    toast("Copied link", {
      autoClose: 3000,
      hideProgressBar: false,
    });
  }

  const toggleShowEditPage = async () => {
    setShowEditPage(!showEditPage);
    if(showProjectOptions){
      setShowProjectOptions(false);
    }
    
    // Always refresh project data when closing edit page
    if (showEditPage) {  // This means we're closing the edit page
      const projectData = await getProjectById(props.user.uid, pid);
      if (projectData) {
        setProject(projectData);
        
        // Refetch posts and update allImages
        const posts = await getPostsByProjectID(props.user.uid, pid);
        setTimelinePosts(posts);

        // Combine project gallery images with post images if enabled
        const projectGallery = projectData.gallery || [];
        let combinedImages = [...projectGallery];
        
        // Only include post images if the setting is enabled
        if (projectData.includePostPhotos !== false) {
          const postImages = posts
            .filter(post => post.imageUrl)
            .map(post => post.imageUrl);
          combinedImages = [...projectGallery, ...postImages];
        }
        
        setAllImages(combinedImages);
      }
    }
  }

  const projectOptionsMenu = [
    {
        icon: "fa-solid fa-pen-to-square",
        text: "Edit Project",
        onClick: () => {
          toggleShowEditPage();
        },
        requiresWriteAccess: true
      },
    {
      icon: "fa-solid fa-share-from-square",
      text: "Share Project",
      onClick: () => {
        handleShareProjectPage();
      },
      requiresWriteAccess: false
    }
  ]

  return (
    <div className="project-page content-below-nav remove-top-margin">
      {showEditPage ? (
        <EditProject 
          user={props.user} 
          project={project} 
          toggleShowEditPage={toggleShowEditPage} 
        />
      ) : (
        <>
          <div className='page-header-nav'>
            <Container fluid>
              <Row>
                <Col xs="2">
                  <div className='circ-btn subtext-gray' onClick={goBack}>
                    <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                  </div>
                </Col>
                <Col className='title' xs="8">
                  <span>{project.title}</span>
                </Col>
                <Col xs="2">
                  <div className='circ-btn subtext-gray' onClick={toggleShowProjectOptions}>
                    <FontAwesomeIcon icon="fa-solid fa-ellipsis" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {showProjectOptions ?
            <PopupOptionsMenu
              user={props.user}
              toggleShowPopupOptions={toggleShowProjectOptions}
              buttons={projectOptionsMenu}
            />
            : null
          }
          
          <div className="project-content">
            {allImages.length > 0 ? (
              <div style={{ position: 'relative' }}>
                <Carousel 
                  className="project-carousel" 
                  controls={false} 
                  indicators={false}
                  onSlide={(index) => setActiveIndex(index)}
                >
                  {allImages.map((photo, index) => (
                    <Carousel.Item key={index} onClick={() => navigateToGallery(index)}>
                      <img
                        className="d-block w-100 clickable-image"
                        src={photo}
                        alt={`Project slide ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
                {allImages.length > 1 && (
                  <div style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    background: 'rgba(0, 0, 0, 0.4)',
                    color: 'white',
                    padding: '4px 12px',
                    borderRadius: '6px',
                    fontSize: '14px'
                  }}>
                    {`${activeIndex + 1} / ${allImages.length}`}
                  </div>
                )}
                <div style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '10px',
                  background: 'rgba(0, 0, 0, 0.4)',
                  color: 'white',
                  padding: '8px 12px',
                  borderRadius: '6px',
                  fontSize: '14px',
                  cursor: 'pointer'
                }} onClick={() => navigateToGallery(activeIndex)}>
                  <FontAwesomeIcon icon="fa-solid fa-grip" />
                </div>
              </div>
            ) : 
              <div style={{
                width: '100%',
                height: '220px',
                backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/human-60275.appspot.com/o/util%2Fdefault-hobby-cover.png?alt=media&token=812390f8-a4e0-4a22-aa8e-150e6878c8af)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}>
              </div>
            }
          </div>

          <Container fluid>
            <Row className="mt-3">
              <Col xs={12}>
                <h3 className="project-main-title">{project.title}</h3>
                <div className="project-started">
                  {project.started ? 
                    (() => {
                      try {
                        const startDate = new Date(project.started.seconds * 1000);
                        startDate.setHours(12);
                        const startStr = startDate.toLocaleDateString('en-US', { 
                          month: 'long', 
                          day: 'numeric', 
                          year: 'numeric' 
                        });

                        if (project.status === 'completed' && project.completed) {
                          const endDate = new Date(project.completed.seconds * 1000);
                          endDate.setHours(12);
                          const endStr = endDate.toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                          });
                          return `${startStr} - ${endStr}`;
                        }
                        
                        return `Started ${startStr}`;
                      } catch (e) {
                        console.error('Error formatting date:', e);
                        return ''; 
                      }
                    })() :
                    ''
                  }
                </div>
              </Col>
            </Row>

            <Row className={project.started ? "mt-4" : ""}>
              <Col xs={12}>
                <div className="project-status">
                  {project.status && project.status !== 'completed' && (
                    <span className={`status-badge ${project.status}`}>
                      {project.status.toUpperCase()}
                    </span>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12}>
                <p className="project-description">{project.description}</p>
              </Col>
            </Row>
            {project.metrics && project.metrics.length > 0 && (
              <Row className="metrics-container mt-3">
                {project.metrics.map((metric, index) => (
                  <Col xs={6} key={index} className="mb-3">
                    <div className="metric-tile">
                      <FontAwesomeIcon icon={metric.icon} className="metric-icon" />
                      <div className="metric-details">
                        <div className="metric-value">{metric.value}</div>
                        <div className="metric-name">{metric.name}</div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            )}
            {/* Project Updates Timeline Section */}
            <Row className="mt-4">
              <Col xs={12}>
                <h5>Project Updates</h5>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ProjectTimeline 
                  posts={timelinePosts} 
                  user={props.user} 
                  project={project} 
                />
              </Col>
            </Row>
          </Container>

          <Outlet />
        </>
      )}
    </div>
  );
}

export default SingleProjectPage; 