import React, { useState, useEffect } from 'react';

import { Row, Col, Container, Button } from 'react-bootstrap';

import ProfileHeader from '../components/profileHeader';
import { Outlet, useLocation, NavLink } from 'react-router-dom';
import { getProjects } from '../functions/firebase';

function Profile(props) {

  const location = useLocation();

  const [isSticky, setIsSticky] = useState(false);
  const [tab, setTab] = useState('story');
  const [hasProjects, setHasProjects] = useState(false);

  useEffect(() => {
    const checkForProjects = async () => {
      if (props.user?.uid) {
        const projects = await getProjects(props.user.uid);
        setHasProjects(projects.length > 0);
      }
    };
    checkForProjects();
  }, [props.user]);

  const changeTab = (tab) => {
    setTab(tab);
  }

  const getActiveTab = () => {
    if (location.pathname.includes('feed')) {
      return 'feed';
    }
    if (location.pathname.includes('hobbies')) {
      return 'hobbies';
    }
    if (location.pathname.includes('projects')) {
      return 'projects';
    }
    return '';
  };

  const activeTab = getActiveTab();

  return (
    <>
      <ProfileHeader user={props.user}/>
      <div className={isSticky ? 'mid-nav top sticky-nav' : 'mid-nav top'}>
          <Container fluid>
          <Row>
              <Col xs="12">
              <NavLink to="feed"><div className={activeTab == 'feed' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('story')}}>Story</div></NavLink>
              <NavLink to="hobbies"><div className={activeTab == 'hobbies' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('hobbies')}}>Hobbies</div></NavLink>
              {hasProjects && (
                <NavLink to="projects"><div className={activeTab == 'projects' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('projects')}}>Projects</div></NavLink>
              )}
              </Col>
          </Row>
          </Container>
      </div>
      <Outlet></Outlet> 
    </>
  );
}

export default Profile;
