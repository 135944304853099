import { Row, Col } from 'react-bootstrap';

function HobbySelector({ hobbies, selectedIndex, onSelect }) {
    return (
        <div className='hobby-list-short'>
            {hobbies ? hobbies.map((hobby, index) => (
                <Row 
                    key={index} 
                    className={`hobby-row ${selectedIndex === index ? 'selected' : ''}`} 
                    onClick={() => onSelect(hobby, index)}
                >
                    <Col xs="2" className="icon-col">
                        <div className={`hobby-category-circle color-${hobby.color_index || '1'} ${selectedIndex === index ? 'selected' : ''}`}></div>
                    </Col>
                    <Col xs="9" className="text-col">
                        <div className="hobby-name">{hobby.hobbyName}</div>
                    </Col>
                </Row>
            )) : <></>}
        </div>
    );
}

export default HobbySelector; 