import React, { useState, useEffect } from 'react';
import { getPaginatedPosts } from '../functions/firebase';
import Feed from './feed';

function AllPostsFeed(props) {
  const [allPosts, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadPosts = async (isInitial = false) => {
    if (loading || (!hasMore && !isInitial)) return;
    
    setLoading(true);
    const lastPost = allPosts.length > 0 ? allPosts[allPosts.length - 1] : null;
    
    const { posts, hasMore: morePostsExist } = await getPaginatedPosts(
      props.user.uid,
      isInitial ? null : lastPost
    );

    setAllPosts(prevPosts => isInitial ? posts : [...prevPosts, ...posts]);
    setHasMore(morePostsExist);
    setLoading(false);
  };

  // Initial load
  useEffect(() => {
    if (props.user != null) {
      loadPosts(true);
    }
  }, [props.user]);

  // Handle scroll event
  const handleScroll = () => {
    if (!allPosts.length) return;

    const posts = document.querySelectorAll('.post');
    if (!posts.length) return;

    const fifthLastPost = posts[Math.max(posts.length - 5, 0)];
    if (!fifthLastPost) return;

    const rect = fifthLastPost.getBoundingClientRect();
    if (rect.top < window.innerHeight * 1.5) {
      loadPosts();
    }
  };

  // Add scroll listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [allPosts, loading, hasMore]);

  return (
    <>
      <Feed posts={allPosts} user={props.user} />
      {loading && <div className="text-center py-4">Loading more posts...</div>}
      {!hasMore && <div className="text-center py-4">No more posts to load</div>}
    </>
  );
}

export default AllPostsFeed;
