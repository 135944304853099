import React, { useState, useEffect, useRef, TouchEvent } from 'react';
import { toast } from 'react-toastify';
import { Nav, Navbar, Row, Col, Container, Button } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faUser, faCirclePlus, faLightbulb, faLocationDot, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { motion, AnimatePresence } from 'framer-motion';

import Feed from '../components/feed';
import AboutHobby from '../components/aboutHobby';
import HobbyHeader from '../components/hobbyHeader';
import PopupOptionsMenu from '../components/popupOptionsMenu';
import { useProfile } from '../assets/data/providerContext';
import EditHobby from './editHobby';
import ProjectsList from '../components/projectsList';

import { Outlet, useLocation, useNavigate, Link } from 'react-router-dom';
import { getPostsByHobby, checkHobbyHasProjects } from '../functions/firebase';
import { getHobbyInfo, getMilestonesOnly, handleShareLink } from '../functions/util_fn';

function HobbyPage(props) {

    const [isSticky, setIsSticky] = useState(false);
    const [tab, setTab] = useState('story');
    const [postsData, setPostsData] = useState(null);
    const [hobbyHeader, setHobbyHeader] = useState({});
    const [showHobbyOptions, setShowHobbyOptions] = useState(false);
    const [showEditPage, setShowEditPage] = useState(false);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [[page, direction], setPage] = useState([0, 0]);
    const [hasProjects, setHasProjects] = useState(false);
    const [showAboutTab, setShowAboutTab] = useState(false);
    const [hasMilestones, setHasMilestones] = useState(false);

    const profileContext = useProfile();
    const profileInfo = profileContext == null ? null : profileContext.profileInfo;

    var location = useLocation();

    const navigate = useNavigate();

    const hobbyHeaderRef = useRef(null);

    useEffect(() => {
        const getHobbyPosts = async (hobbyID) => {
            const posts = await getPostsByHobby(props.user.uid, hobbyID);
            setPostsData(posts);
            setHasMilestones(posts?.some(post => post.isMilestone));
        }

        var hobbyID = null;

        const regex = /\/u\/[^\/]+\/hobby\/([^\/]+)/;
        const match = location.pathname.match(regex);
        hobbyID = match ? match[1] : null;

        getHobbyPosts(hobbyID);

        var hobbyInfo = {
            name: ""
        }
        if(profileInfo){
            hobbyInfo = getHobbyInfo(hobbyID, profileInfo.hobbies);
        }
        setHobbyHeader(hobbyInfo);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (hobbyHeaderRef.current) {
                const headerRect = hobbyHeaderRef.current.getBoundingClientRect();
                setIsSticky(headerRect.bottom <= 0);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        var hobbyID = null;

        const regex = /\/u\/[^\/]+\/hobby\/([^\/]+)/;
        const match = location.pathname.match(regex);
        hobbyID = match ? match[1] : null;

        var hobbyInfo = {
            name: ""
        }
        if(profileInfo){
            hobbyInfo = getHobbyInfo(hobbyID, profileInfo.hobbies);
            const hasAboutContent = hobbyInfo.aboutText || hobbyInfo.aboutImageUrl || hobbyInfo.startDate;
            setShowAboutTab(!!hasAboutContent);
        }
        setHobbyHeader(hobbyInfo);
    },[profileInfo])

    useEffect(() => {
        if (location.state?.activeTab) {
            setTab(location.state.activeTab);
        }
    }, [location]);

    useEffect(() => {
        const checkForProjects = async () => {
            if (hobbyHeader.id) {
                const projectsExist = await checkHobbyHasProjects(props.user.uid, hobbyHeader.id);
                setHasProjects(projectsExist);
            }
        };

        checkForProjects();
    }, [hobbyHeader]);

    const slideVariants = {
        enter: (direction) => ({
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        }),
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1
        },
        exit: (direction) => ({
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        })
    };


    const changeTab = (newTab) => {
        const tabOrder = ['story', 'projects', 'hobbies', 'about'];
        const oldIndex = tabOrder.indexOf(tab);
        const newIndex = tabOrder.indexOf(newTab);
        const newDirection = newIndex - oldIndex;
        
        setPage([newIndex, newDirection]);
        setTab(newTab);
    };

    const goBackToOrigin = () => {
        if(location.state){
            navigate(location.state.origin_url)
        } else {
            navigate(`/u/${props.user.uid}/profile/feed`)
        }
    }

    const toggleShowHobbyOptions = () => {
        setShowHobbyOptions(!showHobbyOptions);
    }

    const toggleShowEditPage = () => {
        setShowEditPage(!showEditPage);
        if(showHobbyOptions){
            setShowHobbyOptions(false);
        }
    }

    const handleShareHobbyPage = async () => {
        await handleShareLink();
        toggleShowHobbyOptions();
        toast("Copied link", {
            autoClose: 3000,
            hideProgressBar: false,
        } );
    }

    const hobbyOptionsMenu = [
        {
            icon: "fa-solid fa-pen-ruler",
            text: "Customize Hobby",
            onClick: () => {
                toggleShowEditPage();
            },
            requiresWriteAccess: true
        },
        {
            icon: "fa-solid fa-share-from-square",
            text: "Share Hobby Page",
            onClick: () => {
                handleShareHobbyPage();
            },
            requiresWriteAccess: false
        }
    ]

    const handleTouchStart = (e) => {
        setTouchStart(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > 50;
        const isRightSwipe = distance < -50;

        const availableTabs = ['story'];
        if (hasProjects) availableTabs.push('projects');
        if (hasMilestones) availableTabs.push('hobbies');
        if (showAboutTab) availableTabs.push('about');

        const currentIndex = availableTabs.indexOf(tab);

        if (isLeftSwipe && currentIndex < availableTabs.length - 1) {
            changeTab(availableTabs[currentIndex + 1]);
        }
        
        if (isRightSwipe && currentIndex > 0) {
            changeTab(availableTabs[currentIndex - 1]);
        }

        setTouchStart(null);
        setTouchEnd(null);
    };

    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            {showEditPage ? <EditHobby user={props.user} hobbyHeader={hobbyHeader} toggleShowEditPage={toggleShowEditPage} /> : 
            <>
                <div className='page-header-nav'>
                    <Container fluid>
                        <Row>
                            <Col xs="2">
                                <div className='circ-btn subtext-gray' onClick={goBackToOrigin}>
                                    <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                                </div>
                            </Col>
                            <Col className='title' xs="8">
                                <span>{isSticky ? hobbyHeader.hobbyName : (profileInfo ? profileInfo.displayName : "")}</span>
                            </Col>
                            <Col xs="2" onClick={()=>{toggleShowHobbyOptions()}}>
                                <div className='circ-btn subtext-gray'>
                                    <FontAwesomeIcon icon="fa-solid fa-ellipsis" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div ref={hobbyHeaderRef}>
                    <HobbyHeader headerData={hobbyHeader} />
                </div>
                <div className={isSticky ? 'mid-nav with-header sticky-nav' : 'mid-nav with-header'}>
                    <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className={tab == 'story' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('story')}}>Latest</div>
                            {hasProjects && (
                                <div className={tab == 'projects' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('projects')}}>Projects</div>
                            )}
                            {hasMilestones && (
                                <div className={tab == 'hobbies' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('hobbies')}}>Milestones</div>
                            )}
                            {showAboutTab && (
                                <div className={tab == 'about' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('about')}}>About</div>
                            )}
                        </Col>
                    </Row>
                    </Container>
                </div>
                {showHobbyOptions ?
                    <PopupOptionsMenu
                        user={props.user}
                        toggleShowPopupOptions={toggleShowHobbyOptions}
                        buttons={hobbyOptionsMenu}
                    />
                    : null
                }
                <Outlet></Outlet>

                <div 
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    style={{ position: 'relative' }}
                >
                    <AnimatePresence initial={false} custom={direction}>
                        <motion.div
                            key={tab}
                            custom={direction}
                            variants={slideVariants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            transition={{
                                x: { duration: 0.5, ease: "easeInOut" },
                                opacity: { duration: 0.4 }
                            }}
                            style={{
                                position: 'absolute',
                                width: '100%',
                                left: 0,
                                right: 0
                            }}
                        >
                            {(() => {
                                switch (tab) {
                                    case 'story':
                                        return <Feed posts={postsData} user={props.user}/>;
                                    case 'projects':
                                        return <ProjectsList user={props.user}/>;
                                    case 'hobbies':
                                        return <Feed posts={getMilestonesOnly(postsData)} user={props.user}/>;
                                    case 'about':
                                        return <AboutHobby user={props.user} hobbyHeader={hobbyHeader} />;
                                    default:
                                        return null;
                                }
                            })()}
                        </motion.div>
                    </AnimatePresence>
                </div>
            </>
            }
        </div>
    );
}

export default HobbyPage;
