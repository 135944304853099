import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import AttributeInput from './attributeInput';
import HobbySelector from './HobbySelector';
import { uploadPhoto, createNewPost } from '../functions/firebase';

function MultiPostForm({ 
    user, 
    profileInfo, 
    onSubmit, 
    showLoadingMessage, 
    hideLoadingMessage 
}) {
    const [posts, setPosts] = useState([]);
    const [currentPostIndex, setCurrentPostIndex] = useState(0);
    const [selectedHobbyIndex, setSelectedHobbyIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleFileSelect = async (event) => {
        const files = Array.from(event.target.files);
        const initialPosts = files.map(file => ({
            'post-photo': file,
            content: '',
            'Add Title': '',
            'Add Location': '',
            'Private': false,
            hobby: null
        }));
        setPosts(initialPosts);
    };

    const updateCurrentPost = (key, value) => {
        setPosts(prevPosts => {
            const updatedPosts = [...prevPosts];
            updatedPosts[currentPostIndex] = {
                ...updatedPosts[currentPostIndex],
                [key]: value
            };
            return updatedPosts;
        });
    };

    const handleHobbySelect = (hobby, index) => {
        setSelectedHobbyIndex(index);
        updateCurrentPost('hobby', hobby);
    };

    const handleContentChange = (e) => {
        updateCurrentPost('content', e.target.value);
    };

    const handleNext = () => {
        if (currentPostIndex < posts.length - 1) {
            setCurrentPostIndex(prev => prev + 1);
        }
    };

    const handleBack = () => {
        if (currentPostIndex > 0) {
            setCurrentPostIndex(prev => prev - 1);
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        showLoadingMessage('Uploading Posts...');
        try {
            // Upload each post
            for (const post of posts) {
                // First upload the photo
                if (post['post-photo']) {
                    const photoUrl = await uploadPhoto(
                        post['post-photo'],
                        user.uid,
                        'photos'
                    );
                    post['post-photo'] = photoUrl;
                }
                
                await createNewPost(user.uid, {
                    content: post.content,
                    hobby: post.hobby,
                    title: post['Add Title'],
                    imageURL: post['post-photo'],
                    location: post['Add Location'],
                    privatePost: post['Private']
                });
            }
            hideLoadingMessage();
            setIsLoading(false);
            onSubmit();
        } catch (error) {
            console.error('Error uploading posts:', error);
            hideLoadingMessage();
            setIsLoading(false);
        }
    };

    if (posts.length === 0) {
        return (
            <Container fluid className="content-below-nav">
                <h1 style={{marginTop: '54px'}}>Add Multiple Photos</h1>
                <p>Each photo you select will be turned into a separate post.</p>
                <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleFileSelect}
                    className="form-control mt-3"
                />
            </Container>
        );
    }

    const currentPost = posts[currentPostIndex];

    return (
        <div className={isLoading ? 'loading-overlay' : ''}>
            <Container fluid className="content-below-nav">
                <p style={{marginTop: '54px'}}>Post {currentPostIndex + 1} of {posts.length}</p>
                
                <HobbySelector 
                    hobbies={profileInfo?.hobbies}
                    selectedIndex={selectedHobbyIndex}
                    onSelect={handleHobbySelect}
                />

                {currentPost['post-photo'] && (
                    <img 
                        src={typeof currentPost['post-photo'] === 'string' 
                            ? currentPost['post-photo'] 
                            : URL.createObjectURL(currentPost['post-photo'])}
                        alt="Selected"
                        className="img-fluid mb-3"
                    />
                )}

                <AttributeInput 
                    addPostInfo={updateCurrentPost} 
                    title="Add Title" 
                    icon="fa-font" 
                    type="text"
                    initialValue={currentPost['Add Title']}
                />

                <textarea 
                    placeholder="Write your thoughts here..."
                    className='outline'
                    value={currentPost.content}
                    onChange={handleContentChange}
                />

                <AttributeInput 
                    addPostInfo={updateCurrentPost} 
                    title="Add Location" 
                    icon="fa-location-dot" 
                    type="text"
                    initialValue={currentPost['Add Location']}
                />

                <AttributeInput 
                    addPostInfo={updateCurrentPost} 
                    title="Private" 
                    icon="fa-eye" 
                    type="checkbox"
                    initialValue={currentPost['Private']}
                />

                <div className="d-flex justify-content-between mt-3">
                    <Button 
                        variant="outline-secondary" 
                        onClick={handleBack}
                        disabled={currentPostIndex === 0 || isLoading}
                    >
                        Previous
                    </Button>
                    {currentPostIndex === posts.length - 1 ? (
                        <Button 
                            variant="primary" 
                            onClick={handleSubmit}
                            disabled={!currentPost.hobby || isLoading}
                        >
                            Share All
                        </Button>
                    ) : (
                        <Button 
                            variant="primary" 
                            onClick={handleNext}
                            disabled={!currentPost.hobby || isLoading}
                        >
                            Next
                        </Button>
                    )}
                </div>
            </Container>
        </div>
    );
}

export default MultiPostForm; 