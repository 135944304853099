import React, { useState } from 'react';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateProject, uploadProjectPhoto, deleteProject } from '../functions/firebase';
import { useNavigate } from 'react-router-dom';

const AVAILABLE_ICONS = [
      // Creative
  { category: 'Creative', icons: [
    'fa-palette', 'fa-paintbrush', 'fa-music', 'fa-camera', 'fa-wand-magic-sparkles',
    'fa-pen-nib', 'fa-masks-theater', 'fa-microphone'
  ]},
  
  
  // Time & Progress
  { category: 'Time & Progress', icons: [
    'fa-clock', 'fa-hourglass', 'fa-stopwatch', 'fa-calendar-days', 'fa-calendar-check',
    'fa-chart-line', 'fa-arrow-trend-up', 'fa-chart-simple'
  ]},
  
  // Achievement & Goals
  { category: 'Achievement', icons: [
    'fa-trophy', 'fa-medal', 'fa-star', 'fa-ranking-star', 'fa-award',
    'fa-certificate', 'fa-circle-check', 'fa-flag-checkered'
  ]},
  // Measurements & Physical
  { category: 'Measurements', icons: [
    'fa-ruler', 'fa-weight-scale', 'fa-ruler-vertical', 'fa-ruler-combined', 'fa-tape',
    'fa-arrows-up-down', 'fa-arrows-left-right', 'fa-temperature-high'
  ]},
  // Fitness & Health
  { category: 'Fitness & Health', icons: [
    'fa-heart-pulse', 'fa-person-running', 'fa-dumbbell', 'fa-bicycle', 'fa-person-swimming',
    'fa-fire', 'fa-apple-whole', 'fa-bed'
  ]},
  
  // Learning & Skills
  { category: 'Learning', icons: [
    'fa-book', 'fa-graduation-cap', 'fa-brain', 'fa-lightbulb', 'fa-code',
    'fa-language', 'fa-puzzle-piece', 'fa-pen'
  ]},
  
  // Finance & Numbers
  { category: 'Numbers', icons: [
    'fa-coins', 'fa-calculator', 'fa-percent', 'fa-hashtag', 'fa-dollar-sign',
    'fa-piggy-bank', 'fa-chart-pie', 'fa-scale-balanced'
  ]},
  
  // Other
  { category: 'Other', icons: [
    // Nature & Weather
    'fa-tree', 'fa-leaf', 'fa-sun', 'fa-cloud', 'fa-moon', 'fa-snowflake', 'fa-mountain', 'fa-fire',
    
    // Activities & Hobbies
    'fa-gamepad', 'fa-dice', 'fa-chess', 'fa-guitar', 'fa-plane', 'fa-car', 'fa-bicycle', 'fa-hiking',
    
    // Objects & Tools
    'fa-key', 'fa-hammer', 'fa-screwdriver', 'fa-wrench', 'fa-glasses', 'fa-bell', 'fa-compass', 'fa-map',
    
    // Tech & Communication
    'fa-mobile', 'fa-laptop', 'fa-wifi', 'fa-headphones', 'fa-microchip', 'fa-robot', 'fa-signal', 'fa-battery-full',
    
    // Miscellaneous
    'fa-heart', 'fa-star', 'fa-crown', 'fa-gem', 'fa-infinity', 'fa-atom', 'fa-rocket', 'fa-earth-americas'
  ]},
];

function EditProject(props) {
    const [title, setTitle] = useState(props.project.title || '');
    const [description, setDescription] = useState(props.project.description || '');
    const [status, setStatus] = useState(props.project.status || 'active');
    const [startDate, setStartDate] = useState(() => {
        if (props.project.started?.seconds) {
            const date = new Date(props.project.started.seconds * 1000);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            return date.toISOString().split('T')[0];
        }
        return '';
    });
    const [endDate, setEndDate] = useState(() => {
        if (props.project.completed?.seconds) {
            const date = new Date(props.project.completed.seconds * 1000);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            return date.toISOString().split('T')[0];
        }
        return '';
    });
    const [isEdited, setIsEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [metrics, setMetrics] = useState(props.project.metrics || []);
    const [activeIconEdit, setActiveIconEdit] = useState(null);
    const [gallery, setGallery] = useState(props.project.gallery || []);
    const [isUploading, setIsUploading] = useState(false);
    const [includePostPhotos, setIncludePostPhotos] = useState(
        props.project.includePostPhotos !== false  // default to true if not set
    );
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const navigate = useNavigate();

    const handleSave = async () => {
        if (!isLoading) {
            setIsLoading(true);
            try {
                const updatedProjectInfo = {
                    title,
                    description,
                    status,
                    started: startDate ? new Date(startDate) : null,
                    completed: endDate ? new Date(endDate) : null,
                    metrics,
                    gallery,
                    includePostPhotos  // Add this field to the update
                };

                await updateProject(props.user.uid, props.project.id, updatedProjectInfo);
                
                setIsEdited(false);
                props.toggleShowEditPage();
            } catch (error) {
                console.error("Error updating project:", error);
            }
            setIsLoading(false);
        }
    };

    const addMetric = () => {
        if (metrics.length < 6) {
            setMetrics([...metrics, {
                name: '',
                value: '',
                icon: 'fa-ruler'
            }]);
            setIsEdited(true);
        }
    };

    const updateMetric = (index, field, value) => {
        const updatedMetrics = [...metrics];
        updatedMetrics[index][field] = value;
        setMetrics(updatedMetrics);
        setIsEdited(true);
    };

    const removeMetric = (index) => {
        const updatedMetrics = metrics.filter((_, i) => i !== index);
        setMetrics(updatedMetrics);
        setIsEdited(true);
    };

    const statusOptions = ['active', 'completed'];

    const openIconSelector = (index) => {
        setActiveIconEdit(index);
    };

    const closeIconSelector = () => {
        setActiveIconEdit(null);
    };

    const selectIcon = (index, icon) => {
        updateMetric(index, 'icon', icon);
        closeIconSelector();
    };

    const IconSelector = ({ index, currentIcon }) => (
        <>
            <div className="icon-popup-overlay" onClick={closeIconSelector} />
            <div className="icon-popup">
                <div className="icon-popup-header">
                    <h5>Select Icon</h5>
                    <button className="icon-popup-close" onClick={closeIconSelector}>
                        <FontAwesomeIcon icon="fa-solid fa-times" />
                    </button>
                </div>
                <div className="icon-categories">
                    {AVAILABLE_ICONS.map((category) => (
                        <div key={category.category} className="icon-category">
                            <h6>{category.category}</h6>
                            <div className="icon-grid">
                                {category.icons.map((icon) => (
                                    <div
                                        key={icon}
                                        className={`icon-option ${currentIcon === icon ? 'selected' : ''}`}
                                        onClick={() => selectIcon(index, icon)}
                                    >
                                        <FontAwesomeIcon icon={['fas', icon.replace('fa-', '')]} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );

    const handlePhotoUpload = async (event) => {
        const files = Array.from(event.target.files);
        setIsUploading(true);
        
        try {
            const uploadPromises = files.map(file => 
                uploadProjectPhoto(file, props.user.uid, props.project.id)
            );
            
            const urls = await Promise.all(uploadPromises);
            const newGallery = [...gallery, ...urls];
            setGallery(newGallery);
            setIsEdited(true);
            
            // Update project immediately with new gallery
            await updateProject(props.user.uid, props.project.id, { gallery: newGallery });
        } catch (error) {
            console.error("Error uploading photos:", error);
        } finally {
            setIsUploading(false);
        }
    };

    const removePhoto = async (urlToRemove) => {
        const newGallery = gallery.filter(url => url !== urlToRemove);
        setGallery(newGallery);
        setIsEdited(true);
        
        // Update project immediately with updated gallery
        await updateProject(props.user.uid, props.project.id, { gallery: newGallery });
    };

    const handleDeleteClick = () => {
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        setIsLoading(true);
        try {
            const success = await deleteProject(props.user.uid, props.project.id);
            if (success) {
                navigate(-1);
            }
        } catch (error) {
            console.error("Error deleting project:", error);
        }
        setIsLoading(false);
        setShowDeleteModal(false);
    };

    const renderMetricsSection = () => (
        <div className="post">
            <Row>
                <Col xs="12">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <span>Project Metrics</span>
                        {metrics.length < 6 && (
                            <button 
                                className="btn btn-sm btn-primary" 
                                onClick={addMetric}
                            >
                                Add Metric
                            </button>
                        )}
                    </div>
                    {[...metrics].reverse().map((metric, index) => {
                        const actualIndex = metrics.length - 1 - index;  // Calculate the real array index
                        return (
                            <div key={actualIndex} className="metric-item">
                                <input
                                    type="text"
                                    placeholder="Metric name"
                                    value={metric.name}
                                    onChange={(e) => updateMetric(actualIndex, 'name', e.target.value)}
                                    className="form-control mb-2"
                                />
                                
                                <input
                                    type="text"
                                    placeholder="Metric value"
                                    value={metric.value}
                                    onChange={(e) => updateMetric(actualIndex, 'value', e.target.value)}
                                    className="form-control mb-3"
                                />
                                
                                <div 
                                    className="icon-display"
                                    onClick={() => openIconSelector(actualIndex)}
                                >
                                    <FontAwesomeIcon icon={['fas', metric.icon.replace('fa-', '')]} />
                                    <span>Change Icon</span>
                                </div>
                                
                                {activeIconEdit === actualIndex && (
                                    <IconSelector 
                                        index={actualIndex}
                                        currentIcon={metric.icon}
                                    />
                                )}
                                
                                <button 
                                    className="secondary-wide-btn"
                                    onClick={() => removeMetric(actualIndex)}
                                    style={{
                                        width: '100%',
                                        marginTop: '12px',
                                        color: '#dc3545',
                                        borderColor: '#dc3545'
                                    }}
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-trash" style={{marginRight: '8px'}} />
                                    Remove Metric
                                </button>
                            </div>
                        );
                    })}
                </Col>
            </Row>
        </div>
    );

    const renderGallerySection = () => (
        <div className="post">
            <Row>
                <Col xs="12">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <span>Project Gallery</span>
                        <input
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handlePhotoUpload}
                            style={{ display: 'none' }}
                            id="gallery-upload"
                            disabled={isUploading}
                        />
                        <label 
                            htmlFor="gallery-upload" 
                            className={`btn btn-sm btn-primary ${isUploading ? 'disabled' : ''}`}
                        >
                            {isUploading ? 'Uploading...' : 'Add Photos'}
                        </label>
                    </div>
                    <div className="edit-gallery-grid">
                        {gallery.map((url, index) => (
                            <div key={index} className="edit-gallery-item">
                                <img src={url} alt={`Gallery item ${index + 1}`} />
                                <button 
                                    className="remove-photo-btn"
                                    onClick={() => removePhoto(url)}
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-times" />
                                </button>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </div>
    );

    return (
        <div className={isLoading ? 'loading-overlay' : ''}>
            <div className='page-header-nav'>
                <Container fluid>
                    <Row>
                        <Col xs="2">
                            <div className={`circ-btn subtext-gray ${isLoading ? 'disabled' : ''}`} 
                                 onClick={!isLoading ? props.toggleShowEditPage : null}>
                                <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                            </div>
                        </Col>
                        <Col className='title' xs="8">
                            <span>Edit Project</span>
                        </Col>
                        <Col xs="2">
                            <div className={`circ-btn primary-text-color ${(!isEdited || isLoading) && 'disabled'}`}>
                                <p onClick={isEdited && !isLoading ? handleSave : null}><b>Save</b></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container fluid className='content-below-nav'>
                <div className="post">
                    <Row>
                        <Col xs="12">
                            <span>Project Title</span>
                            <input 
                                type='text' 
                                value={title} 
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                    setIsEdited(true);
                                }}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="post">
                    <Row>
                        <Col xs="12">
                            <span>Description</span>
                            <textarea
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                    setIsEdited(true);
                                }}
                                rows={4}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="post">
                    <Row>
                        <Col xs="12">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>Project Status</span>
                                <select 
                                    value={status}
                                    onChange={(e) => {
                                        setStatus(e.target.value);
                                        setIsEdited(true);
                                    }}
                                >
                                    {statusOptions.map(status => (
                                        <option key={status} value={status}>
                                            {status.charAt(0).toUpperCase() + status.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="post">
                    <Row>
                        <Col xs="12">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>Start Date</span>
                                <input 
                                    type="date" 
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                        setIsEdited(true);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="post">
                    <Row>
                        <Col xs="12">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>End Date</span>
                                <input 
                                    type="date" 
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                        setIsEdited(true);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                {renderGallerySection()}

                <div className="post">
                    <Row>
                        <Col xs="12">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>Include Post Photos in Gallery</span>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={includePostPhotos}
                                        onChange={(e) => {
                                            setIncludePostPhotos(e.target.checked);
                                            setIsEdited(true);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                            <small className="text-muted">
                                When enabled, photos from project updates will appear in the project gallery
                            </small>
                        </Col>
                    </Row>
                </div>

                {renderMetricsSection()}

                <div className="post">
                    <Row>
                        <Col xs="12">
                            <button 
                                className="secondary-wide-btn"
                                onClick={handleDeleteClick}
                                style={{
                                    width: '100%',
                                    marginTop: '16px',
                                    color: '#dc3545',
                                    borderColor: '#dc3545'
                                }}
                            >
                                <FontAwesomeIcon icon="fa-solid fa-trash" style={{marginRight: '8px'}} />
                                Delete Project
                            </button>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this project? This action cannot be undone and will unlink this project to all associated posts.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        className="secondary-wide-btn"
                        onClick={() => setShowDeleteModal(false)}
                        style={{
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }}
                    >
                        Cancel
                    </button>
                    <button 
                        className="secondary-wide-btn"
                        onClick={handleConfirmDelete}
                        style={{
                            color: '#dc3545',
                            borderColor: '#dc3545',
                            paddingLeft: '10px', 
                            paddingRight: '10px'
                        }}
                    >
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default EditProject; 