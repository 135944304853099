import React from 'react';
import Post from './post';
import MilestonePost from './post-milestone';

function ProjectTimeline({ posts, user, project }) {
  console.log('Timeline posts:', posts);

  if (posts.length === 0 && !project?.started) {
    return <div>No update posts yet</div>;
  }

  const timelineItems = [...posts];
  
  if (project?.started) {
    timelineItems.push({
      postID: 'project-start',
      createdAt: project.started,
      milestone: false,
      title: 'Project Started',
      content: `Started ${project.title}`,
      category: 'milestone',
      color: '#4CAF50'
    });
  }

  timelineItems.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

  return (
    <div className="timeline">
      {timelineItems.map((post) => (
        <div key={post.postID} className="timeline-item">
          <div className="timeline-header">
            <div className="timeline-marker"></div>
            <div className="timeline-date">
              {post.createdAt?.seconds ? 
                new Date(post.createdAt.seconds * 1000).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) 
                : 'No date'}
            </div>
          </div>
          <div className="timeline-content">
            {post.postID === 'project-start' ? (
              <div className="project-start-event">
                <span>{post.content}</span>
              </div>
            ) : post.milestone ? (
              <MilestonePost
                postID={post.postID}
                user={user}
                title={post.title}
                content={post.content}
                hideMenu={true}
                clickable={false}
                category={post.category}
                color={post.color}
                imageUrl={post.imageUrl}
              />
            ) : (
              <Post
                postID={post.postID}
                user={user}
                title={post.title}
                content={post.content}
                hideMenu={true}
                clickable={false}
                category={post.category}
                color={post.color}
                imageUrl={post.imageUrl}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProjectTimeline;