import React, { useState, useEffect } from 'react';
import Post from './post';
import MilestonePost from './post-milestone';
import { useProfile } from '../assets/data/providerContext';
import { getHobbyInfo } from '../functions/util_fn';

function Feed(props) {

  const [allPosts, setAllPosts] = useState(null);
  const profileContext = useProfile();

  useEffect(() => {
    setAllPosts(props.posts);
    console.log(props.posts);
  }, [props.posts]);

  const getColorIndex = (hobbyID) => {
    if (profileContext.profileInfo == null) {
      return 1;
    }
    const hobby = profileContext.profileInfo.hobbies.find((hobby) => hobby.id === hobbyID);
    return hobby.color_index;
  }

  return (
    <div className="feed">

    { allPosts == null ? <div></div> :
      allPosts.length < 1 ? <div className='post'>Nothing posted yet, the story is being written</div> :
      allPosts.map((post, index) => (
        post.milestone ?
        <MilestonePost
          key={index}
          title={post.title}
          content={post.content}
          location={post.location}
          category={post.hobby.hobbyName}
          color={getColorIndex(post.hobby.id)}
          imageUrl={post.imageUrl}
          user={props.user}
          hobbyID={post.hobby.id}
          createdAt={post.postDate}
          clickable={true}
          isPrivate={post.privatePost}
          postID={post.id}
          linked_project={post.linked_project}
        /> :
        <Post
          key={index}
          title={post.title}
          content={post.content}
          location={post.location}
          category={post.hobby.hobbyName}
          color={getColorIndex(post.hobby.id)}
          imageUrl={post.imageUrl}
          user={props.user}
          createdAt={post.postDate}
          hobbyID={post.hobby.id}
          clickable={true}
          isPrivate={post.privatePost}
          postID={post.id}
          linked_project={post.linked_project}
        />
      ))
    }
    </div>
  );
}

export default Feed;
