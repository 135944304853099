import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { getAllUsers } from '../functions/firebase';

function AllUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersList = await getAllUsers();
      setUsers(usersList);
      setLoading(false);
    };

    fetchUsers();
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate(); // Convert Firestore timestamp to JS Date
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="py-4">
      <h1 className="mb-4">All Users</h1>
      <Row className="g-3">
        {users.map(user => (
          <Col key={user.id} xs={12}>
            <Card>
              <Card.Body>
                <a 
                  href={`https://hobbi.ist/u/${user.id}/profile/feed`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="d-flex align-items-center">
                    <img 
                      src={user.profile_pic_url} 
                      alt={user.displayName} 
                      style={{ 
                        width: '50px', 
                        height: '50px', 
                        borderRadius: '50%',
                        objectFit: 'cover',
                        marginRight: '1rem'
                      }}
                    />
                    <div>
                      <Card.Title className="mb-0">
                        {user.displayName || 'Anonymous User'}
                      </Card.Title>
                      <Card.Text className="text-muted mb-0">
                        {user.all_posts_count || 0} posts
                        {(user.location || user.id) && ' • '}
                        {user.location}
                        {user.id && ` • ${user.id.substring(0, 6)}`}
                      </Card.Text>
                      {user.createdAt && (
                        <Card.Text className="text-muted mb-0" style={{ fontSize: '0.9em' }}>
                          Joined {formatDate(user.createdAt)}
                        </Card.Text>
                      )}
                    </div>
                  </div>
                </a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default AllUsers; 